import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './templates/login/login.component';
import { ShareModule } from './shared/share.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiInterceptor } from './authInterceptor/api.interceptor';
import { ORMLogedInPatient } from './models/ormloged-in-patient.model';
import { AuthService } from './services/auth.service';
import { PatientService } from './services/patient.service';
import { NavbarComponent } from './templates/navbar/navbar.component';
import { MainAppComponent } from './templates/main-app/main-app.component';
import { RedirectComponent } from './templates/redirect/redirect.component';
import { LandingPageComponent } from './templates/landing-page/landing-page.component';
import { CopyrightYearDirective } from './directive/copyright-year.directive';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    MainAppComponent,
    RedirectComponent,
    LandingPageComponent,
    CopyrightYearDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
  ],
  exports: [
    CopyrightYearDirective // Export the directive so it can be used in LoginComponent
  ],
  providers: [
    AuthService,
    PatientService,
    ORMLogedInPatient,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
