import { Component, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ORMLogedInPatient } from 'src/app/models/ormloged-in-patient.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationCredentials } from 'src/app/models/authentication-credentials.model';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { EncryptDecryptService } from '../../services/encrypt-decrypt.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  userId: number = 0;
  isEmailInput: boolean = true;
  isPatientId: boolean = false;
  dobFormatError: boolean = false;
  dobFormatMessage = "DOB is required in MMDDYYYY format."
  dobRequiredMessage = "DOB is required"

  unauthorizedError: boolean = false;
  credentialsRequiredError: boolean = false;
  isrememberUsername;
  isrememberPassword;
  userDetail;
  rxSubscription: Subscription = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public router: Router,
    private logedInUser: ORMLogedInPatient,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private authGuard: AuthGuard,
    private encryptDecryptService: EncryptDecryptService,

  ) {
    this.authService.isAuthenticated = false;
  }
  ngOnInit(): void {
    //eslint-disable-next-line
    debugger;
    this.queryParamSubscriber();
    this.buildForm(); // Call the function to initialize the form
    this.getRememberDetail();

  }
  queryParamSubscriber() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.isPatientId = id !== undefined;
      this.isEmailInput = !this.isPatientId;
      this.userId = id || 0;
    });
  }
  buildForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        {
          value: this.userId !== 0 ? this.userId : '',
          disabled: this.isPatientId,
        },
        [
          Validators.required,
          this.isEmailInput ? Validators.email : Validators.nullValidator,
        ],
      ],
      password: ['', [Validators.required]],
      remember: [false], // Initialize with false
    });
  }
  onSubmit() {
    this.validationHandle()
  }
  validationHandle() {
    debugger
    let auth: AuthenticationCredentials = new AuthenticationCredentials();
    const formData = this.loginForm.value;
    let value = this.userId !== 0 ? this.userId : formData.username;
    let dob = formData.password;
    if (formData.remember) {
      this.saveRememberDetails(value, dob, true);
    } else {
      this.forgetRememberedDetails(); // Call function to remove saved credentials
    }

    // Set auth credentials based on remember details
    auth.username = this.shouldUseRememberedDetails() ? this.isrememberUsername : value;
    auth.password = formData.password !== "" ? this.changeDateFormat(dob) : formData.password;
    if (this.loginForm.invalid || this.dobFormatError) {
      this.dobFormatError = !!this.dobFormatError; // Ensure dobFormatError is boolean
      this.credentialsRequiredError = !this.dobFormatError;
    }
    else {
      this.loginApiExecute(auth)
    }

  }

  loginApiExecute(auth) {
    // Creating an object that adheres to the Auth interface
    debugger
    this.rxSubscription = this.authService.authenticateUser(auth).subscribe({
      next: (encryptedData: string | any) => {
        debugger;
        if (encryptedData.status === 200) {
          const encryptedUserDetails = this.encryptDecryptService.decryptUsingAES256(encryptedData.data);

          localStorage.setItem('u.', encryptedData.data);
          this.userDetail = JSON.parse(encryptedUserDetails);
          this.logedInUser.userId = Number(this.userDetail.user_id);
          this.logedInUser.practiceId = Number(this.userDetail.practice_id);
          this.logedInUser.userFullName = this.userDetail.user_fullname;
          this.practiceInfo(this.logedInUser.practiceId);
        }
        else if (encryptedData.status === 401) {
          // Handle Unauthorized error
          this.unauthorizedError = true
          // Add custom logic for handling unauthorized access, such as redirecting to a login page.
          // For example: this.router.navigate(['/login']);
        } else {
          // Handle other errors
          // Add custom error handling logic for other types of errors.
        }
      },
      error: (error: any) => {
        if (error.status === 401) {
          // Handle Unauthorized error
          console.error('Authentication failed - Unauthorized:', error);
          this.unauthorizedError = true
          // Add custom logic for handling unauthorized access, such as redirecting to a login page.
          // For example: this.router.navigate(['/login']);
        } else {
          // Handle other errors
          console.error('Authentication error:', error);
          // Add custom error handling logic for other types of errors.
        }
      },
      complete: () => {
        // console.log('Authentication complete');
        this.router.navigate(['/patient/invoices']);
        // Add any logic you want to execute when the observable completes.
      }
    });


  }

  changeDateFormat(dob: any) {
    if (dob) {
      const reformattedDOB = this.reformatDob(dob);
      this.dobFormatError = reformattedDOB === 'Invalid DOB format';
      return this.dobFormatError ? '' : reformattedDOB;
    }
    return '';
  }


  practiceInfo(practiceId: number) {
    this.authService._practiceInfo(practiceId).subscribe((encryptedData: string | any) => {
      if (encryptedData.status === 200) {
        localStorage.setItem('p.', encryptedData.data);
        const encryptedPracticeDetail = this.encryptDecryptService.decryptUsingAES256(encryptedData.data);
        const practiceDetails = JSON.parse(encryptedPracticeDetail);
        const userDetails = {
          practiceName: practiceDetails[0]?.practice_name,
          patientName: this.userDetail.user_fullname,
        };
        this.authService.setPracticeAndPatientNames(userDetails);

      }
    });
  }

  emailFormatValidator(): ValidatorFn {
    // Regular expression for a basic email format check
    const emailPattern: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value || emailPattern.test(value)) {
        // The email format is valid
        return null;
      } else {
        // The email format is invalid, so return an error object
        return { emailFormat: true };
      }
    };
  }

  reformatDob(dob: any) {
    if (this.validateDobFormat(dob)) {
      const year = dob.slice(4, 8);
      const month = dob.slice(0, 2);
      const day = dob.slice(2, 4);
      const reformattedDob = `${month}-${day}-${year}`;
      return reformattedDob;
    } else {
      return 'Invalid DOB format';
    }
  }

  validateDobFormat(dob: any) {
    const pattern = /^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{4}$/;
    if (!pattern.test(dob)) {
      return false; // Date format is invalid
    }

    const year = parseInt(dob.slice(4, 8), 10);
    const month = parseInt(dob.slice(0, 2), 10);
    const day = parseInt(dob.slice(2, 4), 10);

    const dobDate = new Date(year, month - 1, day);

    const currentDate = new Date();

    if (dobDate > currentDate) {
      return false; // DOB is in the future
    }

    return true;
  }

  getRememberDetail() {
    debugger;
    const encryptedCredentials = localStorage.getItem("c.");
    if (encryptedCredentials !== null) {
      try {
        const decryptedCredentials = JSON.parse(this.encryptDecryptService.decryptUsingAES256(encryptedCredentials));
        const { userName, password, isRemember } = JSON.parse(decryptedCredentials);
        this.loginForm.get("username")?.setValue(userName);
        this.loginForm.get("password")?.setValue(password);
        this.loginForm.get("remember")?.setValue(isRemember);
        if (!Validators.email(userName)) {
          this.isEmailInput = false;
          this.isrememberUsername = userName;
          this.isrememberPassword = password;
          debugger
          // Pass user ID as 'id' parameter in queryParams
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { id: Number(this.isrememberUsername) },
            queryParamsHandling: 'merge',
          });
          this.isPatientId = this.isrememberUsername !== undefined;

          this.isEmailInput = !this.isPatientId;
          this.userId = Number(this.isrememberUsername) || 0;
          // console.log(userName, password, isRemember);
          return isRemember;
        }
      } catch (error) {
        console.error('Error parsing decrypted data:', error);
        // Handle parsing error as needed
      }


    } else {
      this.forgetRememberedDetails();
      return false;
    }
  }

  saveRememberDetails(username: any, password: any, isRemember: any) {
    debugger
    const credentials = JSON.stringify({
      userName: username,
      password: password,
      isRemember: isRemember,
    });

    localStorage.setItem("c.", this.encryptDecryptService.encryptUsingAES256(credentials));
  }

  forgetRememberedDetails() {
    localStorage.removeItem("c.");
  }

  shouldUseRememberedDetails() {
    return this.getRememberDetail();
  }
  ngOnDestroy() {
    // Unsubscribe when the component is destroyed to prevent memory leaks.
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }
  // getRememberDetail() {
  //   debugger;
  //   const savedCredentials = localStorage.getItem("credentials");
  //   if (savedCredentials) {

  //     const { userName, password, isRemember } = JSON.parse(savedCredentials);
  //     // this.isrememberUsername = userName;
  //     // this.isrememberPassword = password;
  //     this.loginForm.get("username")?.setValue(userName);
  //     this.loginForm.get("password")?.setValue(password);
  //     this.loginForm.get("remember")?.setValue(isRemember);
  //     console.log(userName, password, isRemember);
  //     return isRemember;
  //   } else {
  //     this.forgetRememberedDetails();
  //     return false;
  //   }
  // }
}
