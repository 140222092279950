import { Injectable } from '@angular/core';
import { Auth } from '../models/auth';
import { appConfig } from 'src/environments/enviroment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
   constructor(
    private http: HttpClient,
    private router: Router,
    protected encryptDecryptService:EncryptDecryptService
  ) {
    this.initializeFromStorage();

  }
  private patientNameSubject = new BehaviorSubject<string>('');
  patientName$ = this.patientNameSubject.asObservable();
  private userDetailsSubject = new BehaviorSubject<any>({}); // Object to store both practiceName and patientName
  userDetails$ = this.userDetailsSubject.asObservable();

  setPracticeAndPatientNames(userDetails: any) {
  debugger
    //JSON.stringify(userDetails)
    const encryptPracticeAndPatientName = this.encryptDecryptService.encryptUsingAES256(userDetails);    
    localStorage.setItem('p&p', encryptPracticeAndPatientName);
    this.userDetailsSubject.next(userDetails);
  }
  initializeFromStorage() {
    debugger;
    const storedUserDetails = localStorage.getItem('p&p');
    if (storedUserDetails) {
      const decryptPracticeAndPatientName = this.encryptDecryptService.decryptUsingAES256(storedUserDetails);
      const userDetails = JSON.parse(decryptPracticeAndPatientName);
  
      // Make sure this is called only after decryption is completed
      this.userDetailsSubject.next(userDetails);
    }
  }
  
  isAuthenticated: boolean = false;

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  protected apiURL: string = appConfig.apiUrl;


  authenticateUser(auth: Auth) {   
    debugger 
    return this.http.post(this.apiURL + 'auth/generate-patient-portal-token', auth);
  }

  _practiceInfo(practiceId: number) {
    return this.http.post(
      this.apiURL + 'general/get-practice-info/' , practiceId,
      this.httpOptions,
    );
  }

  _reGenerateJwtToken(encrypedtPayload){
    return this.http.post(this.apiURL + 'auth/refresh-token', encrypedtPayload,this.httpOptions);

  }
  
  _logOut() {
    debugger
    this.isAuthenticated = false;
    localStorage.removeItem('u.');
    localStorage.removeItem('p.');
    localStorage.removeItem('p&p');
    this.router.navigate(['/redirect']);
  }
}
