<div class="container-fluid">
  <div class="row no-gutter">
    <!-- The content half -->
    <div class="col-md-12 bg-light bg-img">
      <div class="login d-flex align-items-center px-5">
        <!-- Demo content-->
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-xl-5 mx-auto logoimage" style="height: 26em;max-width: 400px;">
              
              <img class="mb-3 mt-3"
                src="./assets/images/logo.svg"
                alt=""
              />
              <div style="border: 1px solid #25436785;padding: 15px;">
              <span style="font-size: .7rem;font-weight: 600;color: #254367;text-wrap: wrap;">Welcome! Please Enter Your Credentials.</span>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" style="padding-top: 10px;">
                <div class="form-group mb-1" style="height: 3rem;">
                  <input
                  id="inputUsername"
                  [type]="isEmailInput ? 'email' : 'text'"
                  [ngClass]="{
                      'error-border':
                        loginForm.get('username')?.invalid &&
                        loginForm.get('username')?.touched
                    }"
                  placeholder="Email"
                  formControlName="username"
                  class="form-control shadow-sm px-4 py-2"
                  [disabled]="isPatientId"
                />
            <div class="error-message" *ngIf="true">
              <span *ngIf="loginForm.get('username')?.invalid && loginForm.get('username')?.touched">
                <span *ngIf="loginForm.get('username')?.errors?.['required']">Username is required.</span>
                <span *ngIf="loginForm.get('username')?.errors?.['email']">Please enter a valid email.</span>
              </span>
            </div>
                </div>
                <div class="form-group mb-1" style="height: 3rem;">
                  <input
                    id="inputPassword"
                    type="password"
                    placeholder="MMDDYYYY"
                    formControlName="password"
                    minlength="8"
                    maxlength="8"
                    class="form-control shadow-sm px-4 py-2"
                    [ngClass]="{
                      'error-border':
                        loginForm.get('password')?.invalid &&
                        loginForm.get('password')?.touched
                    }"
                  />
                  <div
                    *ngIf="
                      loginForm.get('password')!.invalid &&
                      loginForm.get('password')!.touched
                    "
                    class="error-message"
                    [@fadeInOut]
                  >
                    <span
                      *ngIf="loginForm.get('password')!.errors?.['required']"
                      >{{dobRequiredMessage}}</span
                    >
                    
                  </div>
                  <div *ngIf="dobFormatError" class="error-message text-wrap"  [@fadeInOut]>
                  {{dobFormatMessage}}
                  </div>
                  <div *ngIf="unauthorizedError" class="error-message"  [@fadeInOut]>
                    Credentials are invalid.
                  </div>
                  <div *ngIf="credentialsRequiredError" class="error-message"  [@fadeInOut]>
                    Credentials are Required.
                  </div>
                </div>
                <!-- <div class="custom-control custom-checkbox mb-3">
                  <input
                  id="customCheck1"
                  type="checkbox"
                  formControlName="remember"
                  class="custom-control-input"
                />
                  <label for="customCheck1" class="custom-control-label mx-1"
                    >Remember me</label
                  >
                </div> -->
                <div style="text-align: center;margin-top: 0px;">
                  <button type="submit" class="btn btn-block text-uppercase mb-2 shadow-sm"
                    style="background-color: #254367;color: white;border-radius: 0px;font-size: small; width:100%"> Find My Invoice </button>
                </div>
          
              </form>
              </div>
            </div>
          </div>
        </div>
        <!-- End -->
      </div>
    </div>
    <!-- End -->
    <!-- The image half -->
    <!-- <div class="col-md-7 d-none d-md-flex bg-image">
      <div
        class="col-lg-5 col-xl-4 col-xxl-5 custhgt2 aos-init aos-animate"
        data-aos="fade-zoom-in"
        data-aos-delay="1000"
        id="rightWidgetSection"
      >
        <div class="row" style="padding: 0px;">
          <img class="p-0"
          src="../../../assets/images/right-img.jpg"
          alt="Access Statement"
        />          
        </div>
      </div>
    </div>  -->
  <footer class="text-center footer">&copy; <span appCopyrightYear></span>, iRCM.All Rights Reserved.
  </footer>
  </div>

</div>